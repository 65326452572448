import React from "react";
import CampaignPreview from "../components/CampaignPreview";
import FooterHotlinks from "../components/FooterHotlinks";
import HeroSlider from "../components/HeroSlider";
import MetaTags from "../components/MetaTags";
import LoadingPageIndicator, {
    ShouldShowPageIndicator,
} from "../components/LoadingPageIndicator";
import OurCars from "../components/OurCars/OurCars";
import TripleColumn from "../components/TripleColumn";
import { GetCarListSSR } from "../lib/queries/CarlistQuery";
import { GetHeroSliderSSR } from "../lib/queries/HeroSliderQuery";
import { GetFrontPageDataSSR } from "../lib/queries/pages/FrontPageQuery";
import EquipmentOverall from "../components/EquipmentOverall";
import LargeCtaRow from "../components/LargeCtaRow";

const Index = ({ sliderItems, data, carData }) => {
    if (ShouldShowPageIndicator()) {
        return <LoadingPageIndicator />;
    }
    return (
        <>
            {data.meta && (
                <MetaTags
                    title={data.meta.metatitle}
                    description={data.meta.metadescription}
                    url={data.meta.metaurl}
                    image={data.meta.metaimage}
                    keywords={data.meta.metakeywords}
                />
            )}
            {sliderItems && <HeroSlider props={sliderItems} />}
            <LargeCtaRow />
            <OurCars
                data={carData}
                title={"Våre Biler"}
                frontpageData={data.highlightCar}
            />
            {data && (
                <>
                    {data.promo && (
                        <EquipmentOverall data={data.promo} narrow={true} />
                    )}
                    {data.featured.title && (
                        <CampaignPreview data={data.featured} />
                    )}

                    <TripleColumn
                        data={data.currently.data}
                        title={data.currently.title}
                        subtitle={data.currently.subtitle}
                        news={true}
                        frontpage={true}
                    />
                </>
            )}
            <FooterHotlinks hasTopMargin />
        </>
    );
};

export async function getStaticProps() {
    const carData = {
        categories: ["personbil", "varebil"],
        subCategories: ["alle", "elbil", "plug-in hybrid", "hybrid"],
        cars: [],
        category: "personbil",
        subCategory: "alle",
    };
    carData.cars = await GetCarListSSR();

    const sliderItems = await GetHeroSliderSSR();
    const data = await GetFrontPageDataSSR();

    return {
        props: {
            sliderItems,
            carData,
            data,
        },
        revalidate: 300,
    };
}

export default Index;
