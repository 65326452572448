import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import React, { useState } from "react";
import styled from "styled-components";
import Title from "./Elements/Title";
import { FourWayCampaign } from "./FourWayCampaign";
import ArrowLeft from "./icons/ArrowLeft";
import ArrowRight from "./icons/ArrowRight";

interface SliderButtonsProps {
    readonly currentSlide?: number;
    readonly active?: boolean;
}

const CampaignPreview = ({ data }) => {
    const sliderItems = data;

    const [pause, setPause] = React.useState(false);
    const timer = React.useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
        loop: true,
        initial: 0,
        slideChanged(s) {
            setCurrentSlide(s.details().relativeSlide);
        },
        duration: 3000, // Slide animation duration
        dragStart: () => {
            setPause(true);
        },
        dragEnd: () => {
            setPause(false);
        },
    });

    React.useEffect(() => {
        sliderRef.current.addEventListener("mouseover", () => {
            setPause(true);
        });
        sliderRef.current.addEventListener("mouseout", () => {
            setPause(false);
        });
    }, [sliderRef]);

    React.useEffect(() => {
        timer.current = setInterval(() => {
            if (!pause && slider) {
                slider.next();
            }
        }, 6000); // Slider interval
        return () => {
            clearInterval(timer.current);
        };
    }, [pause, slider]);

    return (
        <>
            <section className="navigation-wrapper">
                <Title heading={"Aktuelle Kampanjer"} />
                <CampaignSliderWrapper ref={sliderRef} className="keen-slider">
                    {sliderItems.map((item) => {
                        return (
                            <Slide
                                key={item.title}
                                className="keen-slider__slide"
                            >
                                <FourWayCampaign
                                    data={item}
                                    noOvertake
                                    noHeading
                                />
                            </Slide>
                        );
                    })}
                    {slider && (
                        <ArrowWrapper>
                            <Arrowleft
                                onClick={(e) =>
                                    e.stopPropagation() || slider.prev()
                                }
                                disabled={currentSlide === 0}
                            />
                            <Arrowright
                                onClick={(e) =>
                                    e.stopPropagation() || slider.next()
                                }
                                disabled={
                                    currentSlide === slider.details().size - 1
                                }
                            />
                        </ArrowWrapper>
                    )}
                </CampaignSliderWrapper>
                {slider && (
                    <DotsRow>
                        {[...Array(slider.details().size).keys()].map((idx) => {
                            return (
                                <Dot
                                    key={idx}
                                    onClick={() => {
                                        slider.moveToSlideRelative(idx);
                                    }}
                                    active={currentSlide === idx}
                                />
                            );
                        })}
                    </DotsRow>
                )}
            </section>
        </>
    );
};

export default CampaignPreview;

function Arrowleft(props) {
    return (
        <StyledArrowLeft onClick={props.onClick}>
            <ArrowPosition>
                <ArrowLeft fill="#FFF" width={30} height={50} />
            </ArrowPosition>
        </StyledArrowLeft>
    );
}

function Arrowright(props) {
    return (
        <StyledArrowRight onClick={props.onClick}>
            <ArrowPosition right={true}>
                <ArrowRight fill="#FFF" width={30} height={50} />
            </ArrowPosition>
        </StyledArrowRight>
    );
}
interface ArrowStyledComponent {
    right?: boolean;
}
const ArrowWrapper = styled.div`
    display: flex;
    max-width: 1940px;
    justify-content: space-between;
`;
const ArrowPosition = styled.div<ArrowStyledComponent>`
    position: relative;
    top: 50%;
    background-color: rgba(0, 0, 0, 0.6);
    max-width: 30px;
    display: flex;
    justify-content: flex-end;
    float: ${(props) => (props.right ? "right" : "none")};
`;
const StyledArrowRight = styled.div`
    width: 5%;
    height: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    fill: #fff;
    cursor: pointer;
    left: auto;
    right: 0;

    @media (max-width: 900px) {
        display: none;
    }
`;

const StyledArrowLeft = styled.div`
    width: 5%;
    height: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    fill: #fff;
    cursor: pointer;
    left: 0;

    @media (max-width: 900px) {
        display: none;
    }
`;

const DotsRow = styled.div`
    display: flex;
    padding: 1rem 0;
    justify-content: center;
    margin-top: -15px;
`;

const Dot = styled.button<SliderButtonsProps>`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 5px;
    padding: 5px;
    cursor: pointer;
    position: relative;

    background: ${(props) =>
        props.active ? props.theme.primary : props.theme.white};
    border: 1px solid
        ${(props) => (props.active ? props.theme.white : props.theme.white)};

    box-shadow: 0px 0px 0px 1px
        ${(props) => (props.active ? props.theme.primary : props.theme.medium)}; ;
`;

const CampaignSliderWrapper = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    max-width: 100vw;
    margin: 0 auto;
    overflow: hidden;
`;
const Slide = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
`;
