import Head from "next/head";

interface MetaTagProps {
    url: string;
    image: string;
    description: string;
    title: string;
    keywords: string;
}

const MetaTags = (meta: MetaTagProps) => {
    return (
        <Head>
            {/* HTML Meta Tags */}
            {meta.title && <title>{meta.title}</title>}
            {meta.description && (
                <meta name="description" content={meta.description} />
            )}
            {/* Keywords */}
            {meta.keywords && <meta name="keywords" content={meta.keywords} />}
            {/* Google / Search Engine Tags */}
            <meta itemProp="name" content="Renault Norge" />
            {meta.description && (
                <meta itemProp="description" content={meta.description} />
            )}
            {/* Unless there is a image in the meta, don't bother adding Twitter and Facebook fields. */}
            {meta.image && (
                <>
                    <meta itemProp="image" content={meta.image} />
                    {/* Twitter Meta Tags */}
                    <meta name="twitter:card" content="summary_large_image" />
                    {meta.title && (
                        <meta name="twitter:title" content={meta.title} />
                    )}
                    {meta.description && (
                        <meta
                            name="twitter:description"
                            content={meta.description}
                        />
                    )}
                    <meta name="twitter:image" content={meta.image} />
                    {/* Facebook Meta Tags */}
                    <meta property="og:url" content={meta.url} />
                    <meta property="og:type" content="website" />
                    {meta.title && (
                        <meta property="og:title" content={meta.title} />
                    )}
                    {meta.description && (
                        <meta
                            property="og:description"
                            content={meta.description}
                        />
                    )}
                    <meta property="og:image" content={meta.image} />
                </>
            )}
        </Head>
    );
};

export default MetaTags;
