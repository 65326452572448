import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import CarBuilder from "./icons/CarBuilder";
import MapPin from "./icons/MapPin";
import TestDrive from "./icons/TestDrive";
import ArrowRight from "./icons/ArrowRight";
import { device } from "../layout/global-styles/device";
import Campaign from "./icons/Campaign";

interface LargeCtaRowProps {
    data?: any;
}

const LargeCtaRow = ({ data }: LargeCtaRowProps) => {
    let elements = null;

    if (data) {
        elements = data;
    } else {
        elements = {
            title: "Hva ser du etter?",
            cta: [
                {
                    href: "/kampanjer",
                    text: "Kampanjer",
                    icon: <Campaign fill={"#000000"} width={48} height={48} />,
                },
                {
                    href: "/prøvekjør",
                    text: "Book prøvekjøring",
                    icon: <TestDrive fill={"#000000"} width={48} height={48} />,
                },
                {
                    href: "/finn-forhandlere",
                    text: "Finn forhandler",
                    icon: <MapPin fill={"#000000"} width={48} height={48} />,
                },
                {
                    href: "/konfigurator",
                    text: "Bygg din bil",
                    icon: (
                        <CarBuilder fill={"#000000"} width={48} height={48} />
                    ),
                },
            ],
        };
    }

    return (
        <Wrapper>
            <Title>{ReactHtmlParser(elements.title)}</Title>
            <Content>
                {elements.cta.map((c, idx) => {
                    return (
                        <LinkWrapper href={c.href} key={c.title + idx}>
                            {c.icon && (
                                <CTA>
                                    <Icon>{c.icon}</Icon>
                                    <CTAtext>{ReactHtmlParser(c.text)}</CTAtext>
                                </CTA>
                            )}
                            {!c.icon && (
                                <CTA>
                                    <Icon>
                                        <ArrowRight
                                            fill={"#000000"}
                                            width={48}
                                            height={48}
                                        />
                                    </Icon>
                                    <CTAtext>{ReactHtmlParser(c.text)}</CTAtext>
                                </CTA>
                            )}
                        </LinkWrapper>
                    );
                })}
            </Content>
        </Wrapper>
    );
};

export default LargeCtaRow;

const Wrapper = styled.section`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 3rem auto 0;
`;

const Content = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 1rem;
`;

const CTA = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Title = styled.h2`
    font-family: "nouvelr-bold";
`;

const CTAtext = styled.p`
    font-family: "nouvelr-bold";
    color: ${(props) => props.theme.black};
    line-height: 1em;
`;

const Icon = styled.div`
    margin-right: 8px;

    @media ${device.tablet} {
        margin-right: 16px;
    }
`;

const LinkWrapper = styled.a`
    text-decoration: none;
    margin: 4px;
    padding: 8px;
    border: solid 1.5px ${(props) => props.theme.medium};
    border-radius: 2px;
    max-width: 230px;
    min-height: 80px;
    display: flex;
    justify-content: center;
    flex: 1 1 calc(50% - 8px);

    transition: 250ms;

    &:hover {
        border: solid 1.5px ${(props) => props.theme.primary};
    }

    @media ${device.tablet} {
        padding: 16px;
        flex: 1 1;
    }
`;
