const Campaign = ({ fill, width, height }) => {
    return (
        <svg viewBox="0 0 60 60" height={height} width={width} fill={fill}>
            <g>
                <path
                    d="M12.48,47.396l-2.521,1.859l-1.38-1.5l2.521-1.859c2.939-2.221,5.1-3,8.159-3c4.14,0,8.04,1.92,14.1,2.16l15.119-10.381
                    c0.601-0.42,0.84-0.719,0.84-1.139c0-0.781-0.6-1.44-1.38-1.44c-0.42,0-0.659,0.12-1.14,0.42l-6.779,4.56
                    c-0.54,1.26-1.801,1.98-3.48,1.98h-8.699v-2.04h8.699c1.021,0,1.62-0.48,1.62-1.38c0-0.9-0.6-1.381-1.62-1.381h-16.02
                    c-4.02,0-5.76,0.541-8.579,2.761l-1.98,1.56l-1.38-1.5l1.98-1.56c3.239-2.521,5.52-3.3,10.02-3.3h15.959c1.74,0,3.12,0.9,3.54,2.34
                    l5.819-3.84c0.721-0.48,1.261-0.66,2.04-0.66c1.92,0,3.48,1.561,3.48,3.48c0,1.199-0.48,1.859-2.22,3.059l-14.88,10.5
                    c-6.479,0-10.619-2.16-15.06-2.16C16.499,44.935,14.999,45.476,12.48,47.396z M10.68,22.077c0-2.94,0.18-3.36,3.119-6.84
                    c3-3.601,4.44-4.44,7.62-4.44h7.62c3.359,0,5.22,0.601,7.979,2.461l4.56,3.42l2.939,0.719c3.181,0.721,4.681,2.58,4.681,5.641
                    c0,1.5-0.421,2.82-1.2,3.899l-1.68-1.2c0.6-0.899,0.779-1.56,0.779-2.699c0-2.1-1.08-3.24-3.779-3.9l-2.94-0.72l-4.439-3.36
                    c-2.16-1.619-4.02-2.219-6.899-2.219h-7.62c-2.46,0-3.36,0.6-6.12,3.899c-2.34,2.88-2.52,3.18-2.52,5.64v1.26
                    c0,0.9,0.18,1.439,0.72,1.98l-1.439,1.319c-0.96-0.84-1.38-1.86-1.38-3.3V22.077z M36.418,25.736c0-2.52,1.74-4.199,4.26-4.199
                    c2.58,0,4.26,1.68,4.26,4.199c0,2.521-1.739,4.2-4.26,4.2c-1.739,0-3.119-0.84-3.779-2.159H23.039
                    c-0.66,1.319-2.04,2.159-3.78,2.159c-2.52,0-4.26-1.739-4.26-4.2c0-2.52,1.74-4.199,4.26-4.199c2.58,0,4.26,1.68,4.26,4.199H36.418
                    z M19.259,23.517c-1.38,0-2.279,0.84-2.279,2.219c0,1.32,0.899,2.221,2.279,2.221s2.28-0.84,2.28-2.221
                    C21.539,24.357,20.7,23.517,19.259,23.517z M40.678,23.517c-1.38,0-2.279,0.84-2.279,2.219c0,1.32,0.899,2.221,2.279,2.221
                    s2.28-0.84,2.28-2.221C42.958,24.357,42.119,23.517,40.678,23.517z"
                />
            </g>
        </svg>
    );
};

export default Campaign;
