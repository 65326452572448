import React, { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import styled from "styled-components";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import ReactHtmlParser from "react-html-parser";
import { device } from "../../layout/global-styles/device";
import SecondaryButton from "../Elements/SecondaryButton";
import { Dash, DashRow } from "../Slider/Dash";
import TertiaryButton from "../Elements/TertiaryButton";

interface IHeroSliderProps {
    opacity?: number;
}

const HeroSlider = ({ props }) => {
    const sliderItems = props;

    const [currentSlide, setCurrentSlide] = useState(0);
    const [opacity, setOpacity] = useState(0);

    const [pause, setPause] = React.useState(false);
    const timer = React.useRef(null);
    const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
        loop: true,
        initial: 0,
        slideChanged(s) {
            setCurrentSlide(s.details().relativeSlide);
        },
        duration: 2500, // Slide animation duration
        dragStart: () => {
            setPause(true);
        },
        dragEnd: () => {
            setPause(false);
        },
    });

    React.useEffect(() => {
        sliderRef.current.addEventListener("mouseover", () => {
            setPause(true);
        });
        sliderRef.current.addEventListener("mouseout", () => {
            setPause(false);
        });
    }, [sliderRef]);

    React.useEffect(() => {
        timer.current = setInterval(() => {
            if (!pause && slider) {
                slider.next();
            }
        }, 7000); // Slider interval
        return () => {
            clearInterval(timer.current);
        };
    }, [pause, slider]);

    useEffect(() => {
        setOpacity(1);
    }, []);

    return (
        <>
            <OuterWrapper className="navigation-wrapper">
                <HeroSliderWrapper
                    opacity={opacity}
                    ref={sliderRef}
                    className="keen-slider"
                >
                    {sliderItems.map((item) => {
                        return (
                            <Slide key={item.id} className="keen-slider__slide">
                                {item.video ? (
                                    <HeroVideo>
                                        <video autoPlay muted loop playsInline>
                                            <source
                                                src={item.video}
                                                type="video/mp4"
                                            />
                                        </video>
                                    </HeroVideo>
                                ) : (
                                    <HeroImageContainer>
                                        <DesktopHeroImage className="desktopImage">
                                            <HeroImage
                                                unoptimized={true}
                                                src={item.image}
                                                priority={true}
                                                layout="fill"
                                                objectFit="cover"
                                                loading="eager"
                                                objectPosition="50% 50%"
                                            />
                                        </DesktopHeroImage>
                                        <MobileHeroImage className="mobileImage">
                                            <HeroImage
                                                unoptimized={true}
                                                src={item.mobileImage}
                                                priority={true}
                                                layout="fill"
                                                objectFit="cover"
                                                loading="eager"
                                                objectPosition="50% 75%"
                                            />
                                        </MobileHeroImage>
                                    </HeroImageContainer>
                                )}
                                <InfoContainer>
                                    {item.heading && (
                                        <Heading>
                                            {ReactHtmlParser(item.heading)}
                                        </Heading>
                                    )}
                                    {item.subheading && (
                                        <SubHeading>
                                            {ReactHtmlParser(item.subheading)}
                                        </SubHeading>
                                    )}
                                    {item.price && (
                                        <Price>
                                            {ReactHtmlParser(item.price)}
                                        </Price>
                                    )}
                                    {item.href && (
                                        <Link href={item.href} passHref>
                                            <ButtonWrapper>
                                                {item.video ? (
                                                    <SecondaryButton
                                                        outline
                                                        text={"les mer"}
                                                    />
                                                ) : (
                                                    <TertiaryButton
                                                        text={"les mer"}
                                                    />
                                                )}
                                            </ButtonWrapper>
                                        </Link>
                                    )}
                                </InfoContainer>
                            </Slide>
                        );
                    })}
                </HeroSliderWrapper>
                {slider && (
                    <DashRow>
                        {[...Array(slider.details().size).keys()].map((idx) => {
                            return (
                                <ClickableArea
                                    key={idx}
                                    onClick={() => {
                                        slider.moveToSlideRelative(idx);
                                    }}
                                >
                                    <Dash active={currentSlide === idx} />
                                </ClickableArea>
                            );
                        })}
                    </DashRow>
                )}
            </OuterWrapper>
        </>
    );
};
export default HeroSlider;

const ButtonWrapper = styled.a`
    margin-top: 15px;
`;

const ClickableArea = styled.a`
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const OuterWrapper = styled.section`
    position: relative;
`;

const HeroSliderWrapper = styled.div<IHeroSliderProps>`
    display: flex;
    position: relative;
    height: 68vh;
    width: 100%;
    max-width: 100vw;
    margin: 0 auto;
    overflow: hidden;
    opacity: ${(props) => props.opacity};
    transition: opacity 0.5s ease-in-out;

    @media ${device.tablet} {
        height: 35rem;
    }

    @media ${device.laptop} {
        height: 40rem;
    }
`;

const Slide = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
`;

const HeroImageContainer = styled.div`
    .desktopImage {
        display: none;
    }

    .mobileImage {
        display: block;
    }

    @media ${device.tablet} {
        .desktopImage {
            display: block;
        }

        .mobileImage {
            display: none;
        }
    }
`;

const DesktopHeroImage = styled.div``;

const MobileHeroImage = styled.div``;

const HeroImage = styled(Image)`
    position: relative;
    max-width: 100%;
`;

const InfoContainer = styled.div<IHeroSliderProps>`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: ${(props) => props.theme.white};
    text-align: left;
    max-width: 90%;
    z-index: 1000;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);

    bottom: 10%;
    left: 5%;
    padding: 1rem;

    @media ${device.tablet} {
        max-width: 50%;
        top: 10%;
        left: 10%;
        padding: 1.5rem;
    }

    @media ${device.laptop} {
        max-width: 550px;
    }

    background-color: "transparent";
`;

const Heading = styled.h1`
    font-family: "nouvelr-bold";
    font-size: 1.2rem;

    @media ${device.tablet} {
        font-size: 35px;
        margin-bottom: 10px;
    }
    @media ${device.laptopM} {
        font-size: 50px;
    }
`;

const SubHeading = styled.div`
    font-size: 1rem;
    line-height: 1.4rem;
    margin: 0.5rem 0;

    @media ${device.tablet} {
        font-size: 22px;
        line-height: 1.6rem;
    }
`;

const Price = styled.div`
    font-family: "nouvelr-bold";
    font-size: 1.2rem;

    @media ${device.tablet} {
        font-size: 28px;
        line-height: 1.625rem;
        margin-bottom: 1.2rem;
        max-width: 280px;
    }
    @media ${device.laptopM} {
        font-size: 32px;
        line-height: 0.9em;
    }
`;

const HeroVideo = styled.div`
    height: 100%;
    width: 100%;
    max-width: 100%;
    position: absolute;
    left: 0;
    top: 0;

    video {
        object-fit: cover;
        min-height: 20rem;
        height: 100%;
        width: 100%;
    }
`;
