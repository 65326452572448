import React from "react";

interface ArrowRightProp {
    fill: string;
    width: number;
    height: number;
}

const ArrowRight: React.FC<ArrowRightProp> = ({ fill, width, height }) => {
    return (
        <svg
            version="1.1"
            height={height}
            width={width}
            fill={fill}
            x="0px"
            y="0px"
            viewBox="0 0 60 60"
        >
            <g>
                <path
                    d="M18.479,47.096l-1.32-1.86c7.8-4.979,13.74-9.239,21.119-15.299c-7.379-6-13.319-10.26-21.119-15.239l1.32-1.86
            c8.699,5.7,14.76,10.08,22.199,16.079v2.101C32.998,37.197,26.819,41.636,18.479,47.096z"
                />
            </g>
        </svg>
    );
};

export default ArrowRight;
