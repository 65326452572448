import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

interface LoadingStyleProps {
    height?: string;
}

const StyledWrapper = styled.div<LoadingStyleProps>`
    height: ${(props) => (props.height ? props.height : "95vh")};
    display: flex;
    justify-content: center;
    align-content: center;
`;

const Loader = styled.div`
    position: relative;
    z-index: 10;
    width: 100%;
    height: 100%;
    min-height: 64px;
`;

const RenaultLogo = styled.svg`
    width: 32px;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    display: block;
    max-height: 48px;
    min-height: 24px;
    fill: none;
    fill-rule: evenodd;
    stroke: rgb(0, 0, 0);
    stroke-width: 6;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-dasharray: 176;
    stroke-dashoffset: 0;
`;

const LoaderLogo = styled.polygon`
    fill: none;
    fill-rule: evenodd;
    stroke: rgb(0, 0, 0);
    stroke-width: 6;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-dasharray: 176;
    stroke-dashoffset: 0;
`;

const LoaderProgress = styled.polygon`
    stroke: rgb(239, 223, 0);
    stroke-dashoffset: 176;
    stroke-width: 7;
    animation: 4s linear 0s infinite normal none running filling;
`;

const LoadingPageIndicator = ({ height = null }) => {
    const [offset, setOffset] = useState(176);
    const ref = useRef(null);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setOffset(offset > 0 ? offset - 1 : 176);
            ref.current.style.strokeDashoffset = offset;
        }, 10);

        return () => {
            clearTimeout(timeout);
        };
    }, [offset]);

    return (
        <>
            <StyledWrapper height={height}>
                <Loader>
                    <RenaultLogo fill="none" viewBox="0 0 60 72">
                        <LoaderLogo points="20.56649 67.17988 2 35.903459 20.56649 4 36.28263 4 53.07732 35.903459 36.203914 67.17988"></LoaderLogo>
                        <LoaderProgress
                            ref={ref}
                            strokeDashoffset={offset}
                            points="20.56649 67.17988 2 35.903459 20.56649 4 36.28263 4 53.07732 35.903459 36.203914 67.17988"
                        ></LoaderProgress>
                    </RenaultLogo>
                </Loader>
            </StyledWrapper>
        </>
    );
};

export const ShouldShowPageIndicator = () => {
    const router = useRouter();
    return router.isFallback;
};

export default LoadingPageIndicator;
