import Image from "next/image";
import styled from "styled-components";

import { Centered } from "./Centered";
import Title from "./Elements/Title";
import PrimaryButton from "./Elements/PrimaryButton";
import SecondaryButton from "./Elements/SecondaryButton";
import PrimaryLink from "./Elements/PrimaryLink";

import { device } from "../layout/global-styles/device";
import { LinkData } from "../lib/LinkData";
import { MediaCard } from "../lib/queries/FourWayMediaQuery";
import PageOvertake from "./PageOvertake";
import React, { useState } from "react";
import { IColorPickerData } from "../lib/queries/CarColorPickerQuery";
import Link from "next/link";
import ReactHtmlParser from "react-html-parser";

export interface MediaData {
    title: string;
    subtitle: string;
    headingTwo?: string;
    media?: MediaCard[];
    primary?: LinkData;
    secondary?: LinkData;
    subtitleBeforeCTA?: boolean;
}

interface FourWayMediaProps {
    data: MediaData;
    carData?: IColorPickerData[];
    designData?: object;
    id?: string;
    noHeading?: boolean;
    noOvertake?: boolean;
}
interface TitleCheck {
    title: string;
}

const FlexWrapper = styled.section`
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 1920px;

    @media ${device.desktop} {
        width: 90%;
        margin: 0 auto;
    }
`;

const HeadingTwo = styled.h3`
    text-align: center;
    padding: 1rem 1rem;
    text-transform: uppercase;
`;

const Subtitle = styled.div<{ order: number }>`
    color: ${(props) => props.theme.black};
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 0 1rem 1rem;
    order: ${({ order }) => (order > 0 ? order : "")};
    font-size: 1.1rem;
    span {
        font-size: 12px;
        color: ${(props) => props.theme.neutral};
    }
    @media ${device.tablet} {
        width: 90%;
    }
`;

const MediaWrapper = styled.div`
    display: none;
    justify-content: space-between;
    grid-gap: 1rem;

    flex-direction: column;
    align-items: center;
    margin: 1rem 0;

    @media ${device.tablet} {
        display: flex;
        flex-direction: row;
        align-items: unset;
    }
`;
const MediaWrapperSmall = styled.div`
    display: flex;
    justify-content: center;

    flex-direction: column;
    align-items: center;
    margin: 1rem 0;

    @media ${device.tablet} {
        display: none;
    }
`;

const MediaStyled = styled.div<TitleCheck>`
    width: 265px;
    height: 265px;
    display: flex;

    @media ${device.tablet} {
        width: 200px;
        height: 200px;
    }
    @media ${device.laptop} {
        flex-direction: row;
        width: 465px;
        height: 265px;
    }

    @media ${device.laptopL} {
        height: 390px;
    }

    @media ${device.desktop} {
        height: 465px;
    }

    background: ${(props) => (props.title ? "black" : "unset")};

    h4 {
        color: ${(props) => props.theme.white};
        padding-bottom: 1rem;
        text-transform: uppercase;

        font-size: 1.8rem;

        @media ${device.tablet} {
            font-size: 1.3rem;
        }

        @media ${device.laptop} {
            font-size: 1.8rem;
        }

        @media ${device.laptopL} {
            font-size: 2.5rem;
        }
    }
`;

const TextCard = styled.div`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Media: React.FC<MediaCard> = ({ image, title, callToAction }) => {
    return (
        <MediaStyled title={title}>
            {title && (
                <TextCard>
                    <h4>{title}</h4>
                    {callToAction && (
                        <PrimaryLink {...callToAction} light={true} />
                    )}
                </TextCard>
            )}
            {!title && image && (
                <Image
                    unoptimized={true}
                    priority={true}
                    src={image}
                    width={465}
                    height={465}
                    alt={title}
                    objectFit="cover"
                    placeholder="blur"
                    blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAQAAAAm93DmAAAAJklEQVR42u3MMQEAAAgDoC251Q2hJwSgmbyqUCgUCoVCoVAoFN4spocUAbrGeLoAAAAASUVORK5CYII="
                />
            )}
        </MediaStyled>
    );
};

const ButtonLink = styled.a`
    padding: 1rem; ;
`;

const ButtonsWrapper = styled(Centered)<{ order: number }>`
    flex-direction: column;
    order: ${({ order }) => (order > 0 ? order : "")};

    @media ${device.laptop} {
        flex-direction: row;
    }
`;

export const FourWayCampaign: React.FC<FourWayMediaProps> = ({
    data,
    carData,
    designData,
    id = "",
    noHeading,
    noOvertake = false,
}) => {
    const {
        title,
        subtitle,
        media,
        primary,
        secondary,
        subtitleBeforeCTA,
        headingTwo,
    } = data;

    const [showModal, setShowModal] = useState(false);

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    return (
        <FlexWrapper id={id}>
            {!noHeading && <Title heading={title} />}
            {headingTwo && <HeadingTwo>{headingTwo}</HeadingTwo>}
            <Subtitle order={subtitleBeforeCTA ? 1 : 0}>
                {ReactHtmlParser(subtitle)}
            </Subtitle>
            <MediaWrapper>
                {media &&
                    media
                        .slice(0, 4)
                        .map((m, i) => <Media key={m.image + i} {...m} />)}
            </MediaWrapper>
            <MediaWrapperSmall>
                {media &&
                    media
                        .slice(0, 1)
                        .map((m, i) => <Media key={m.image + i} {...m} />)}
            </MediaWrapperSmall>
            <ButtonsWrapper order={subtitleBeforeCTA ? 1 : 0}>
                {primary && noOvertake && (
                    <Link href={primary.href} passHref>
                        <ButtonLink>
                            <PrimaryButton text={primary.text} />
                        </ButtonLink>
                    </Link>
                )}
                {primary && !noOvertake && (
                    <ButtonLink onClick={toggleModal}>
                        <PrimaryButton text={primary.text} />
                    </ButtonLink>
                )}
                <PageOvertake
                    data={data}
                    showModal={showModal}
                    setShowModal={setShowModal}
                    carData={carData}
                    designData={designData}
                    componentType={"product"}
                />
                {secondary && (
                    <Link href={secondary.href} passHref>
                        <ButtonLink>
                            <SecondaryButton text={secondary.text} />
                        </ButtonLink>
                    </Link>
                )}
            </ButtonsWrapper>
        </FlexWrapper>
    );
};
